// The description of page and keywords.
const SEO = [
	{
		page: "home",
		description:
			"Cheng-Yu Hsieh is a versatile software engineer and blockchain developer with deep expertise in a wide range of technologies including Python, Docker, Kubernetes, Azure, Looker, Node.js, React, MongoDB, Express, RESTful API, Solidity, Google Cloud Platform (GCP), Django, SQLite, AWS, Hardhat, and Ethers. ",
		keywords: ["Cheng-Yu Hsieh", "San Jose State University", "Software Engineer", "432Hz Capital", "ByToken", "blockchain developer", "Python developer", "Docker expert", "Kubernetes specialist", "Azure cloud engineer", "Looker developer", "Node.js developer", "React developer", "MongoDB expert", "RESTful API developer", "Solidity programmer", "GCP specialist", "Django developer", "AWS expert", "Hardhat developer", "Ethers.js developer"],
	},

	{
		page: "about",
		description:
			"Cheng-Yu Hsieh is a versatile software engineer and blockchain developer with deep expertise in a wide range of technologies including Python, Docker, Kubernetes, Azure, Looker, Node.js, React, MongoDB, Express, RESTful API, Solidity, Google Cloud Platform (GCP), Django, SQLite, AWS, Hardhat, and Ethers. ",
		keywords: ["Cheng-Yu Hsieh", "San Jose State University", "Software Engineer", "432Hz Capital", "ByToken"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
		keywords: ["Cheng-Yu Hsieh", "San Jose State University", "Software Engineer", "432Hz Capital", "ByToken", "tech blog", "programming insights", "software development articles", "blockchain articles", "tech leadership blog"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to.",
		keywords: ["Cheng-Yu Hsieh", "San Jose State University", "Software Engineer", "432Hz Capital", "ByToken",  "open-source projects", "software portfolio", "blockchain projects", "Python projects", "web development projects"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Cheng-Yu Hsieh", "San Jose State University", "Software Engineer", "432Hz Capital", "ByToken", "hire software engineer", "blockchain consultation", "tech collaboration", "software development services"],
	},
];

export default SEO;
